import React, { useEffect, useState } from "react";
import { FormattedMessage, IntlProvider, useIntl } from "react-intl";
import Header from "./Header";
import Footer from "./Footer";
import enMessages from "../locales/en.json";
import zhMessages from "../locales/zh.json";
import ptMessages from "../locales/pt.json";
import viMessages from "../locales/vi.json";
const supportedLanguages = [
  "en",
  "zh",
  "pt",
  "vi",
]; // Add your supported languages here
function TermsOfUse(props) {
  const intl = useIntl();
  const browserLanguage = (navigator.language || navigator.userLanguage).split(
    "-"
  )[0];

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [headerHeight, setHeaderHeight] = useState(0);
  const messages = {
    en: enMessages,
    zh: zhMessages,
    pt: ptMessages,
    vi: viMessages,
  };
  // const handleLanguageChange = (e) => {
  //   const language = e.currentTarget.getAttribute("value");
  //   setSelectedLanguage(language);
  //   localStorage.setItem("selectedLanguage", language); // Store selected language
  //   window.location.reload();
  // };

  const handleLanguageChange = (e) => {
    const language = e.currentTarget.getAttribute("value");
    localStorage.setItem("selectedLanguage", language); // Store selected language

    // Delay the language change logic for 3 seconds after reload
    setTimeout(() => {
      setSelectedLanguage(language); // Update the language state after 3 seconds
    }, 3000);

    // Reload the page immediately
    window.location.reload();
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");

    if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
      setSelectedLanguage(storedLanguage);
    } else if (supportedLanguages.includes(browserLanguage)) {
      setSelectedLanguage(browserLanguage);
    } else {
      setSelectedLanguage("en"); // Fallback to default language
    }
  }, [browserLanguage]);

  return (
    <IntlProvider
    locale={selectedLanguage}
    messages={messages[selectedLanguage]}
  >
    <div>
    <Header handleLanguageChange={handleLanguageChange} />
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="page-header-box">
                <h1 class="text-anime-style-3"><FormattedMessage id="terms_of_use_title" defaultMessage="Terms of Use" /></h1>
                <div
                  class="post-single-meta wow fadeInUp"
                  data-wow-delay="0.25s"
                >
                  <ul>
                    <li >
                      <i class="fa-solid "></i><FormattedMessage id="terms_of_use" defaultMessage="Welcome to Serene Browser. These Terms of Use govern your access to and use of our browser, ensuring a smooth, safe, and secure experience. By using Serene Browser, you agree to comply with these terms, which outline your rights and obligations. Please read them carefully to understand the conditions under which you can use our browser." />

                    </li>
                    {/* <li>
                      <i class="fa-solid fa-tag"></i>Website Development
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-single-post">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="post-content">
                <div class="post-entry">
                  <blockquote class="wow fadeInUp" data-wow-delay="0.5s">
                    <p><FormattedMessage id="acceptance_of_terms" defaultMessage="Acceptance of Terms" /></p>
                  </blockquote>
                  <p class="wow fadeInUp" data-wow-delay="0.75s">
                  <FormattedMessage id="acceptance_of_terms_text" defaultMessage="By using Serene Browser, you agree to be bound by these Terms of Use. If you do not accept these terms, you should not use the browser. Continued use of Serene Browser indicates your acceptance of these terms." />
                  </p>
                  <hr></hr>
                  <blockquote class="wow fadeInUp" data-wow-delay="0.5s">
                    <p><FormattedMessage id="license_and_usage" defaultMessage="License and Usage" /></p>
                  </blockquote>
                  <p class="wow fadeInUp" data-wow-delay="0.75s">
                  <FormattedMessage id="license_and_usage_text" defaultMessage="Serene Browser grants you a limited, non-exclusive, non-transferable license to use the browser in accordance with these terms. The browser may not be sold, rented, or used for any unauthorized commercial purposes without prior written consent." />
                  </p>

                  <hr></hr>

                  <blockquote class="wow fadeInUp" data-wow-delay="0.5s">
                    <p><FormattedMessage id="user_responsibilities" defaultMessage="User Responsibilities" /></p>
                  </blockquote>
                  <p class="wow fadeInUp" data-wow-delay="0.75s">
                  <FormattedMessage id="user_responsibilities_text" defaultMessage="You are responsible for your use of Serene Browser, including the accuracy of information provided and the security of your account. You agree not to misuse the browser or violate any applicable laws while using it." />
                  </p>
                  <hr></hr>

                  <blockquote class="wow fadeInUp" data-wow-delay="0.5s">
                    <p><FormattedMessage id="prohibited_conduct" defaultMessage="Prohibited Conduct" /></p>
                  </blockquote>
                  <p class="wow fadeInUp" data-wow-delay="0.75s">
                  <FormattedMessage id="prohibited_conduct_text" defaultMessage="When using Serene Browser, you agree not to engage in the following prohibited activities: Unauthorized access to the browser’s systems, Reverse engineering, decompiling, or disassembling the browser’s software, Disrupting the browser's normal functioning through malicious means, Using the browser for any illegal activities or harmful conduct." />
                  </p>
                  <hr></hr>

                  <blockquote class="wow fadeInUp" data-wow-delay="0.5s">
                    <p><FormattedMessage id="intellectual_property" defaultMessage="Intellectual Property" /></p>
                  </blockquote>
                  <p class="wow fadeInUp" data-wow-delay="0.75s">
                  <FormattedMessage id="intellectual_property_text" defaultMessage="All content, including software, graphics, and trademarks, related to Serene Browser are the intellectual property of Serene or its licensors. Unauthorized use, copying, or distribution of any content from the browser is strictly prohibited." />
                  </p>
                  <hr></hr>

                  <blockquote class="wow fadeInUp" data-wow-delay="0.5s">
                    <p> <FormattedMessage id="privacy" defaultMessage="Privacy" /></p>
                  </blockquote>
                  <p class="wow fadeInUp" data-wow-delay="0.75s">
                  <FormattedMessage id="privacy_text" defaultMessage="Your privacy is important to us. Serene Browser is committed to protecting your personal information and data. Please review our Privacy Policy to understand how we collect, use, and safeguard your information." />
                    <a href="/policy"> [Privacy Policy]</a>.
                  </p>
                  <hr></hr>

                  <blockquote class="wow fadeInUp" data-wow-delay="0.5s">
                    <p><FormattedMessage id="updates_and_changes" defaultMessage="Updates and Changes" /></p>
                  </blockquote>
                  <p class="wow fadeInUp" data-wow-delay="0.75s">
                  <FormattedMessage id="updates_and_changes_text" defaultMessage="Serene Browser reserves the right to modify or update these Terms of Use at any time without prior notice. Any changes will be effective immediately upon posting. Your continued use of the browser after the changes indicates your acceptance of the updated terms." />
                  </p>
                  <hr></hr>
                  <blockquote class="wow fadeInUp" data-wow-delay="0.5s">
                    <p><FormattedMessage id="disclaimer_of_warranties" defaultMessage="Disclaimer of Warranties" /></p>
                  </blockquote>
                  <p class="wow fadeInUp" data-wow-delay="0.75s">
                  <FormattedMessage id="disclaimer_of_warranties_text" defaultMessage="Serene Browser is provided 'as is' without any warranties, either express or implied, including but not limited to warranties of merchantability or fitness for a particular purpose. We do not guarantee that the browser will be error-free, secure, or uninterrupted." />
                  </p>
                  <hr></hr>
                  <blockquote class="wow fadeInUp" data-wow-delay="0.5s">
                    <p><FormattedMessage id="limitation_of_liability" defaultMessage="Limitation of Liability" /></p>
                  </blockquote>
                  <p class="wow fadeInUp" data-wow-delay="0.75s">
                  <FormattedMessage id="limitation_of_liability_text" defaultMessage="Serene Browser shall not be liable for any indirect, incidental, or consequential damages arising from the use of or inability to use the browser, including loss of data or damage to devices, even if we have been advised of the possibility of such damages." />
                  </p>
                  <hr></hr>
                  <blockquote class="wow fadeInUp" data-wow-delay="0.5s">
                    <p><FormattedMessage id="termination" defaultMessage="Termination" /></p>
                  </blockquote>
                  <p class="wow fadeInUp" data-wow-delay="0.75s">
                  <FormattedMessage id="termination_text" defaultMessage="Serene Browser reserves the right to terminate or suspend your access to the browser at any time, for any reason, including violation of these Terms of Use. Upon termination, all licenses and rights granted to you will immediately cease." />
                  </p>                 
                </div>         
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
    </IntlProvider>
  );
}

export default TermsOfUse;
