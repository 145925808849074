import React, { useEffect, useState } from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import enMessages from "./locales/en.json";
import zhMessages from "./locales/zh.json";
import ptMessages from "./locales/pt.json";
import viMessages from "./locales/vi.json";
import { FormattedMessage, IntlProvider, useIntl } from "react-intl";

const Policy = () => {
  const intl = useIntl();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const messages = {
    en: enMessages,
    zh: zhMessages,
    pt: ptMessages,
    vi: viMessages,
  };

  const handleLanguageChange = (e) => {
    const language = e.currentTarget.getAttribute("value");
    localStorage.setItem("selectedLanguage", language); // Store selected language

    // Delay the language change logic for 3 seconds after reload
    setTimeout(() => {
      setSelectedLanguage(language); // Update the language state after 3 seconds
    }, 3000);

    // Reload the page immediately
    window.location.reload();
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
    // Preloader Effec
  }, []);
  return (
    <IntlProvider
      locale={selectedLanguage}
      messages={messages[selectedLanguage]}
    >
      <div>
        <Header handleLanguageChange={handleLanguageChange} />
        <div class="page-header">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="page-header-box">
                  <h1 class="text-anime-style-3" style={{ color: "#4c85f1" }}>
                    <FormattedMessage id="privacyPolicyTitle" />
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="post-content">
          {/* <!-- Post Entry Start --> */}
          <div class="post-entry">
            <p class="wow fadeInUp" data-wow-delay="0.25s">
              <FormattedMessage id="privacyPolicyIntro" />
            </p>
          </div>
          {/* <!-- Post Entry End --> */}
        </div>

        <div class="faq-section">
          <div class="container">
            <div class="row">
              <div class="col-lg-10 offset-lg-1  col-md-12 offset-md-0">
                <div class="faq-accordion" id="accordion">
                  {/* <!-- FAQ Item start --> */}
                  <div
                    class="accordion-item wow fadeInUp"
                    data-wow-delay="0.25s"
                  >
                    <h2 class="accordion-header" id="headingOne">
                      <button
                        class="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <FormattedMessage id="faqTitle1" />
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        <p>
                          <FormattedMessage id="faqContent1" />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- FAQ Item End --> */}

                  {/* <!-- FAQ Item Start --> */}
                  <div
                    class="accordion-item wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    <h2 class="accordion-header" id="headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <FormattedMessage id="faqTitle2" />
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        <p>
                          <FormattedMessage id="faqContent2" />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- FAQ Item End --> */}

                  {/* <!-- FAQ Item Start --> */}
                  <div
                    class="accordion-item wow fadeInUp"
                    data-wow-delay="0.75s"
                  >
                    <h2 class="accordion-header" id="headingThree">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <FormattedMessage id="faqTitle3" />
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        <p>
                          <FormattedMessage id="faqContent3" />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- FAQ Item End --> */}

                  {/* <!-- FAQ Item Start --> */}
                  <div class="accordion-item wow fadeInUp" data-wow-delay="1s">
                    <h2 class="accordion-header" id="headingFour">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <FormattedMessage id="faqTitle4" />
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        <p>
                          <FormattedMessage id="faqContent4" />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- FAQ Item End --> */}

                  {/* <!-- FAQ Item Start --> */}
                  <div
                    class="accordion-item wow fadeInUp"
                    data-wow-delay="1.25s"
                  >
                    <h2 class="accordion-header" id="headingFive">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        <FormattedMessage id="faqTitle5" />
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        <p>
                          <FormattedMessage id="faqContent5" />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- FAQ Item End --> */}

                  {/* <!-- FAQ Item Start --> */}
                  <div
                    class="accordion-item wow fadeInUp"
                    data-wow-delay="1.25s"
                  >
                    <h2 class="accordion-header" id="headingSix">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        <FormattedMessage id="faqTitle6" />
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        <p>
                          <FormattedMessage id="faqContent6" />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- FAQ Item End --> */}

                  {/* <!-- FAQ Item Start --> */}
                  <div
                    class="accordion-item wow fadeInUp"
                    data-wow-delay="1.5s"
                  >
                    <h2 class="accordion-header" id="headingSeven">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        <FormattedMessage id="faqTitle7" />
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        <p>
                          <FormattedMessage id="faqContent7" />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- FAQ Item End --> */}

                  {/* <!-- FAQ Item Start --> */}
                  <div
                    class="accordion-item wow fadeInUp"
                    data-wow-delay="1.5s"
                  >
                    <h2 class="accordion-header" id="headingEight">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        <FormattedMessage id="faqTitle8" />
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        <p>
                          <FormattedMessage id="faqContent8" />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- FAQ Item End --> */}

                  {/* <!-- FAQ Item Start --> */}
                  <div
                    class="accordion-item wow fadeInUp"
                    data-wow-delay="1.5s"
                  >
                    <h2 class="accordion-header" id="headingnine">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapsenine"
                        aria-expanded="false"
                        aria-controls="collapsenine"
                      >
                        <FormattedMessage id="faqTitle9" />
                      </button>
                    </h2>
                    <div
                      id="collapsenine"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingnine"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        <p>
                          <FormattedMessage id="faqContent9" />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- FAQ Item End --> */}

                  {/* <!-- FAQ Item Start --> */}
                  <div
                    class="accordion-item wow fadeInUp"
                    data-wow-delay="1.5s"
                  >
                    <h2 class="accordion-header" id="headingTen">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTen"
                        aria-expanded="false"
                        aria-controls="collapseTen"
                      >
                        <FormattedMessage id="faqTitle10" />
                      </button>
                    </h2>
                    <div
                      id="collapseTen"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTen"
                      data-bs-parent="#accordion"
                    >
                      <div class="accordion-body">
                        <p>
                          <FormattedMessage id="faqContent10" />
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <!-- FAQ Item End --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <Footer />
      </div>
    </IntlProvider>
  );
};

export default Policy;
