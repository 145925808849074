import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { IntlProvider } from "react-intl";
import enMessages from "./locales/en.json";
import zhMessages from "./locales/zh.json";
import { BrowserRouter, Routes, Route } from "react-router-dom"; // Import routing components
import App from "./App"; // Home or Main Component
import Policy from "./policy"; // Policy Component
// import UserGuide from "./UserGuide";
import TermsOfUse from "./Components/TermsOfUse";
import UserGuide from "./Components/UserGuide";

const messages = {
  en: enMessages,
  zh: zhMessages,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <IntlProvider locale="en" messages={messages["en"]}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/policy" element={<Policy />} />
          <Route path = "/termsofuse" element={<TermsOfUse/>}/>
          <Route path = "/userguide" element={<UserGuide/>}/>
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  </React.StrictMode>
);
