import React from 'react';
import { FormattedMessage } from 'react-intl';

function FeaturesSection(props) {
    return (
 <div id="features" class="our-services">
        <div class="container">
            <div class="row section-row align-items-center">
                <div class="col-lg-7 col-md-7">
                    {/* <!-- Section Title Start --> */}
                    <div class="section-title">
                        <h3 class="wow fadeInUp"><FormattedMessage id="Key Features" defaultMessage="Key Features" /></h3>
                        <h2 class="text-anime-style-3"><FormattedMessage id="What Serene Browser Offers" defaultMessage="What Serene Browser Offers" /></h2>
                    </div>
                    {/* <!-- Section Title End --> */}
                </div>

                <div class="col-lg-5 col-md-5">
                    {/* <!-- Section Btn Start --> */}
                    <div class="section-btn">
                        {/* <!-- <a href="#" class="btn-default wow fadeInUp" data-wow-delay="0.25s">view all services</a> --> */}
                    </div>
                    {/* <!-- Section Btn End --> */}
                </div>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    {/* <!-- Service Item Start --> */}
                    <div class="service-item wow fadeInUp" data-wow-delay="0.25s">
                        <div class="service-content">
                            <div class="service-content-title">
                                <h2><FormattedMessage id="Privacy at its Core." defaultMessage="Privacy at its Core." /></h2>
                                <a href="#"><img src={`${process.env.PUBLIC_URL}/Styles/images/arrow.svg`} alt=""/></a>
                            </div>
                            <p><FormattedMessage id="Privacy at its CoreDesc" defaultMessage="Privacy at its Core." /></p>                            
                        </div>
                        <div class="service-image">
                            <figure class="image-anime">
                                <img src={`${process.env.PUBLIC_URL}/Styles/images/service-img-1.avif`} alt=""/>
                            </figure>
                        </div>
                    </div>
                    {/* <!-- Service Item End --> */}
                </div>

                <div class="col-lg-4 col-md-6">
                    {/* <!-- Service Item Start --> */}
                    <div class="service-item wow fadeInUp" data-wow-delay="0.5s">
                        <div class="service-content">
                            <div class="service-content-title">
                                <h2><FormattedMessage id="Lightning-Fast Performance." defaultMessage="Lightning-Fast Performance." /></h2>
                                <a href="#"><img src={`${process.env.PUBLIC_URL}/Styles/images/arrow.svg`} alt=""/></a>
                            </div>
                            <p><FormattedMessage id="Lightning-Fast PerformanceDesc" defaultMessage="Lightning-Fast PerformanceDesc" /></p>
                        </div>
                        <div class="service-image">
                            <figure class="image-anime">
                                <img src={`${process.env.PUBLIC_URL}/Styles/images/service-img-2.avif`} alt=""/>
                            </figure>
                        </div>
                    </div>
                    {/* <!-- Service Item End --> */}
                </div>

                <div class="col-lg-4 col-md-6">
                    {/* <!-- Service Item Start --> */}
                    <div class="service-item wow fadeInUp" data-wow-delay="0.75s">
                        <div class="service-content">
                            <div class="service-content-title">
                                <h2><FormattedMessage id="Minimalist Design" defaultMessage="Minimalist Design" /></h2>
                                <a href="#"><img src={`${process.env.PUBLIC_URL}/Styles/images/arrow.svg`} alt=""/></a>
                            </div>
                            <p><FormattedMessage id="Minimalist DesignDesc" defaultMessage="Minimalist DesignDesc" /></p>
                        </div>
                        <div class="service-image">
                            <figure class="image-anime">
                                <img src={`${process.env.PUBLIC_URL}/Styles/images/service-img-3.avif`} alt=""/>
                            </figure>
                        </div>
                    </div>
                    {/* <!-- Service Item End --> */}
                </div>

                <div class="col-lg-4 col-md-6">
                    {/* <!-- Service Item Start --> */}
                    <div class="service-item wow fadeInUp" data-wow-delay="1s">
                        <div class="service-content">
                            <div class="service-content-title">
                                <h2><FormattedMessage id="Low Resource Usage" defaultMessage="Low Resource Usage" /></h2>
                                <a href="#"><img src={`${process.env.PUBLIC_URL}/Styles/images/arrow.svg`} alt=""/></a>
                            </div>
                            <p><FormattedMessage id="Low Resource UsageDesc" defaultMessage="Low Resource UsageDesc" /></p>
                        </div>                        
                        <div class="service-image">
                            <figure class="image-anime">
                                <img src={`${process.env.PUBLIC_URL}/Styles/images/service-img-4.jpg`} alt=""/>
                            </figure>
                        </div>
                    </div>
                    {/* <!-- Service Item End --> */}
                </div>

                <div class="col-lg-4 col-md-6">
                    {/* <!-- Service Item Start --> */}
                    <div class="service-item wow fadeInUp" data-wow-delay="1.25s">
                        <div class="service-content">
                            <div class="service-content-title">
                                <h2><FormattedMessage id="Voice Search Integration" defaultMessage="Voice Search Integration." /></h2>
                                <a href="#"><img src={`${process.env.PUBLIC_URL}/Styles/images/arrow.svg`} alt=""/></a>
                            </div>
                            <p><FormattedMessage id="Voice Search IntegrationDesc" defaultMessage="Voice Search IntegrationDesc" /></p>
                        </div>
                        <div class="service-image">
                            <figure class="image-anime">
                                <img src={`${process.env.PUBLIC_URL}/Styles/images/service-img-5.jpg`} alt=""/>
                            </figure>
                        </div>
                    </div>
                    {/* <!-- Service Item End --> */}
                </div>

                <div class="col-lg-4 col-md-6">
                    {/* <!-- Service Item Start --> */}
                    <div class="service-item wow fadeInUp" data-wow-delay="1.5s">
                        <div class="service-content">
                            <div class="service-content-title">
                                <h2><FormattedMessage id="Incognito Mode" defaultMessage="Incognito Mode" /></h2>
                                <a href="#"><img src={`${process.env.PUBLIC_URL}/Styles/images/arrow.svg`} alt=""/></a>
                            </div>
                            <p><FormattedMessage id="Incognito ModeDesc" defaultMessage="Incognito ModeDesc" /></p>
                        </div>
                        <div class="service-image">
                            <figure class="image-anime">
                                <img src={`${process.env.PUBLIC_URL}/Styles/images/incognito.png`} alt=""/>
                            </figure>
                        </div>
                    </div>
                    {/* <!-- Service Item End --> */}
                </div>
            </div>
        </div>
    </div>
    );
}

export default FeaturesSection;