import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';


function Header({ handleLanguageChange }) {

    const navigate = useNavigate();

        // Function to close the navbar
        const closeNavbar = () => {
            const navbarCollapse = document.getElementById('navbarNav');
            if (navbarCollapse.classList.contains('show')) {
                navbarCollapse.classList.remove('show');
            }
        };
          // Use useEffect to add event listeners for outside clicks and scroll events
    
          // Function to handle navigation to home and sections
    const handleSectionClick = (sectionId) => {
        navigate('/'); // Navigate to home
        setTimeout(() => {
            window.location.hash = sectionId; // Scroll to the section
        }, 0);
        closeNavbar();
    };
    
          useEffect(() => {
        // Close the navbar when clicking outside
        const handleOutsideClick = (event) => {
            const navbar = document.querySelector('.navbar');
            if (navbar && !navbar.contains(event.target)) {
                closeNavbar();
            }
        };

        // Close the navbar on scroll
        const handleScroll = () => {
            closeNavbar();
        };

        // Attach the event listeners
        document.addEventListener('click', handleOutsideClick);
        window.addEventListener('scroll', handleScroll);

        // Cleanup event listeners on unmount
        return () => {
            document.removeEventListener('click', handleOutsideClick);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Close the navbar when clicking on any nav item
    const handleNavItemClick = () => {
        closeNavbar();
    };
    return (
        <header id="headerbar" class="main-header">
            <div  class="header-sticky">
                <nav class="navbar navbar-expand-lg">
                    <div class="container">
                        {/* <!-- Logo Start --> */}
                        <a class="navbar-brand" href="/">
                            <div style={{ display: "flex" }}>
                                <h3 class="ml-2" style={{ color: "#4c85f1" }}><FormattedMessage id="Serene Browser" defaultMessage="Select language"/></h3>
                            </div>
                            {/* <img src="images/logo.svg" alt="Logo" /> */}
                        </a>


                         {/* Hamburger Button */}
                         <button className="navbar-toggler mr-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse main-menu" id="navbarNav" >   {/* Hamburger id */}
                            <div class="nav-menu-wrapper">
                                <ul class="navbar-nav mr-auto" id="menu">
                                    {/* <!-- <li class="nav-item submenu"><a class="nav-link" href="index-2.html">home</a>
                                    <ul>                                        
                                        <li class="nav-item"><a class="nav-link" href="index.html">Hero Layout 1</a></li>
                                        <li class="nav-item"><a class="nav-link" href="index-3.html">Hero Layout 2</a></li>
                                        <li class="nav-item"><a class="nav-link" href="index-4.html">Hero Layout 3</a></li>
                                    </ul>
                                </li>                                 --> */}
                                    <li class="nav-item "><a class="nav-link " href="#home" onClick={() => handleSectionClick('home')}> <FormattedMessage id="home" defaultMessage="home" /></a></li>
                                    <li class="nav-item"><a class="nav-link" href="#about"    onClick={() => handleSectionClick('about')}><FormattedMessage id="about" defaultMessage="about" /></a></li>
                                    <li class="nav-item"><a class="nav-link" href="#features" onClick={() => handleSectionClick('features')}><FormattedMessage id="feature" defaultMessage="feature" /></a></li>
                                    <li class="nav-item"><a class="nav-link" href="#why"  onClick={() => handleSectionClick('why')}><FormattedMessage id="why" defaultMessage="why" /></a></li>
                                    {/* <li class="nav-item"><a class="nav-link" href="/policy">Policy</a></li> */}
                                    <li class="nav-item submenu"><a class="nav-link disabled" style={{color:"Black",fontWeight:"Bold"}} href=""><FormattedMessage id="select_language" defaultMessage="Select language" /></a>
                                    <ul>
                                            <li class="nav-item" value="en" onClick={(e) => handleLanguageChange(e)}><a class="nav-link" href="#">
                                                {/* 英语 */}
                                                {/* <FormattedMessage id="English" defaultMessage="Change Language" /> */}
                                                English
                                                </a></li>
                                            <li class="nav-item" value="zh" onClick={(e) => handleLanguageChange(e)}><a class="nav-link" href="#"> 
                                                {/* <FormattedMessage id="Chinese" defaultMessage="Change Language" /> */} 
                                                简体中文 (Simplified Chinese)
                                                 </a></li>
                                            <li class="nav-item"  value="pt" onClick={(e) => handleLanguageChange(e)}><a class="nav-link" href="#">
                                                {/* <FormattedMessage id="Portuguese" defaultMessage="Change Language" /> */}
                                                Português (Portuguese)
                                                </a></li>
                                            <li class="nav-item"  value="vi" onClick={(e) => handleLanguageChange(e)}><a class="nav-link" href="#">
                                                {/* <FormattedMessage id="Vietnamese" defaultMessage="Change Language" /> */}
                                                Tiếng Việt (Vietnamese)
                                                </a></li>
                                        </ul>
                                    </li>
                                    {/* <!-- <li class="nav-item highlighted-menu"><a class="nav-link" href="contact-us.html">free consultation</a></li>                                --> */}
                                </ul>
                            </div>
                            {/* <!-- <div class="header-btn d-inline-flex">
                            <a href="contact-us.html" class="btn-default">free consultation</a>
                        </div> --> */}
                        </div>

                        {/* <div class="navbar-toggle"></div>    */}
                        
                    </div>
                </nav>
                <div class="responsive-menu"></div>
            </div>
        </header>
    );
}

export default Header;