import React, { useEffect, useState } from 'react';


function Loader2(props) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 300); // Loader will be visible for 2 seconds

        // Cleanup function to clear the timer
        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return (
            <div className="preloader">
                <div className="loading-container">
                    <div className="loading"></div>
                    <div id="loading-icon">
                        {/* You can uncomment the below lines to include any icons or text */}
                        <img src={`${process.env.PUBLIC_URL}/Styles/images/Applogo.png`} alt=""/>
                        {/* <h3>S&nbsp; B</h3> */}
                    </div>
                </div>
                <div id="magic-cursor">
                    <div id="ball"></div>
                </div>
            </div>
        );
    }

    // Render your main content here after loading
    return (
        <div>
        
        </div>
    );
}

export default Loader2;
