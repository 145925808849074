import React, { useEffect, useState } from "react";
import { FormattedMessage, IntlProvider, useIntl } from "react-intl";
import Header from "./Header";
import Footer from "./Footer";
import enMessages from "../locales/en.json";
import zhMessages from "../locales/zh.json";
import ptMessages from "../locales/pt.json";
import viMessages from "../locales/vi.json";
const supportedLanguages = [
  "en",
  "zh",
  "pt",
  "vi",
]; // Add your supported languages here
function UserGuide(props) {
  const intl = useIntl();
  const browserLanguage = (navigator.language || navigator.userLanguage).split(
    "-"
  )[0];

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [headerHeight, setHeaderHeight] = useState(0);
  const messages = {
    en: enMessages,
    zh: zhMessages,
    pt: ptMessages,
    vi: viMessages,
  };
  // const handleLanguageChange = (e) => {
  //   const language = e.currentTarget.getAttribute("value");
  //   setSelectedLanguage(language);
  //   localStorage.setItem("selectedLanguage", language); // Store selected language
  //   window.location.reload();
  // };

  const handleLanguageChange = (e) => {
    const language = e.currentTarget.getAttribute("value");
    localStorage.setItem("selectedLanguage", language); // Store selected language

    // Delay the language change logic for 3 seconds after reload
    setTimeout(() => {
      setSelectedLanguage(language); // Update the language state after 3 seconds
    }, 3000);

    // Reload the page immediately
    window.location.reload();
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");

    if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
      setSelectedLanguage(storedLanguage);
    } else if (supportedLanguages.includes(browserLanguage)) {
      setSelectedLanguage(browserLanguage);
    } else {
      setSelectedLanguage("en"); // Fallback to default language
    }
  }, [browserLanguage]);

  return (
    <IntlProvider
    locale={selectedLanguage}
    messages={messages[selectedLanguage]}
  >
    <div>
    <Header handleLanguageChange={handleLanguageChange} />
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="page-header-box">
                <h1
                  class="text-anime-style-3"
                  style={{ color: "rgb(76, 133, 241)" }}
                >
                 <FormattedMessage id="user_guide" defaultMessage="User Guide" />
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="our-work">
        <div class="container">
          <div class="row section-row align-items-center">
            <div class="col-lg-8 col-md-9">
              {/* <!-- Section Title Start --> */}
              <div class="section-title">
                {/* <h3 class="wow fadeInUp">
                  <FormattedMessage
                    id="Core Capabilities"
                    defaultMessage="Core Capabilities"
                  />
                </h3> */}
                <h2 class="text-anime-style-3">
                  {/* <FormattedMessage
                    id="Step-by-Step Installation Guide"
                    defaultMessage="Step-by-Step Installation Guide"
                  /> */}
                  <FormattedMessage id="user_manual" defaultMessage="User Manual" />
                </h2>
              </div>
              {/* <!-- Section Title End --> */}
            </div>
          </div>

          <div
            class="row works-item wow fadeInUp"
            style={{ padding: "0px !important;" }}
          >
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/Step1.png`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2><FormattedMessage id="splash_screen" defaultMessage="Splash Screen" /></h2>
                <p>
                <FormattedMessage id="splash_screen_text" defaultMessage="On start app, this screen is visible until required resources are initialized by OS for the app." />
                </p>
              </div>
            </div>
          </div>
          {/* <!-- Works Item End --> */}

          <div class="row works-item wow fadeInUp" style={{ padding: "0px;" }}>
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/permission1.png`}
                      alt=""
                    />
                      <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/permission2.png`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2><FormattedMessage id="permissions_screen" defaultMessage="Permissions Screen" /></h2>
                <p>
                <FormattedMessage id="permissions_screen_text" defaultMessage="In this screen, the app asks for permission to take pictures, record video, and record audio." />
                </p>
              </div>
            </div>
          </div>

          <div class="row works-item wow fadeInUp" style={{ padding: "0px;" }}>
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/setpin.png`}
                      alt=""
                    />
                   
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2><FormattedMessage id="set_pin_screen" defaultMessage="Set Pin Screen" /></h2>
                <p>
                <FormattedMessage id="set_pin_screen_text" defaultMessage="On this screen, the user is required to create a PIN to protect the browser from unauthorized access. This PIN must be entered each time the app is opened for the first time or if the app is removed from the recent apps and then reopened, ensuring the authenticity of the users." />
                </p>
              </div>
            </div>
          </div>

          <div class="row works-item wow fadeInUp" style={{ padding: "0px;" }}>
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/Picture4.png`}
                      alt=""
                    />
                  </figure>
                </div>
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/pin1.png`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2><FormattedMessage id="confirm_pin_screen" defaultMessage="Confirm Pin Screen" /></h2>
                <p>
                <FormattedMessage id="confirm_pin_screen_text" defaultMessage="The user must confirm the PIN by re-entering it after the initial creation. This step ensures that the PIN is correct and will be saved for future use." />
                </p>
              </div>
            </div>
          </div>

          <div class="row works-item wow fadeInUp" style={{ padding: "0px;" }}>
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/pattern1.png`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>

            
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2><FormattedMessage id="set_pattern_screen" defaultMessage="Set Pattern Screen" /></h2>
                <p>
                <FormattedMessage id="set_pattern_screen_text" defaultMessage="Users have the option to set either a PIN or a pattern to protect the browser from unauthorized access." />
                </p>
              </div>
            </div>
          </div>

          <div class="row works-item wow fadeInUp" style={{ padding: "0px;" }}>
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/pattern.png`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>

            
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2><FormattedMessage id="confirm_pattern_screen" defaultMessage="Confirm Pattern Screen" /></h2>
                <p>
                <FormattedMessage id="confirm_pattern_screen_text" defaultMessage="The user must confirm the pattern by re-entering it after the initial creation. This step ensures that the pattern is correct and will be saved for future use." />
                </p>
              </div>
            </div>
          </div>         

          <div class="row works-item wow fadeInUp" style={{ padding: "0px;" }}>
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/browserhome.png`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2> <FormattedMessage id="browser_home_screen" defaultMessage="Browser Home Screen" /></h2>
                <p>
                <FormattedMessage id="browser_home_screen_text" defaultMessage="This is the Home screen of the browser from where the user can use the browser and visit any website. The user can search using voice recognition or by entering text here." />
 
                </p>
              </div>
            </div>
          </div>

          <div class="row works-item wow fadeInUp" style={{ padding: "0px;" }}>
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/forgot password.png`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2> <FormattedMessage id="enter_pin" defaultMessage="Enter Pin" /></h2>
                <p>
                <FormattedMessage id="enter_pin_text" defaultMessage="This screen will appear when the user opens the app for the first time after setting the password or when the application is removed from the recent tabs." />
                </p>
              </div>
            </div>
          </div>

          <div class="row works-item wow fadeInUp" style={{ padding: "0px;" }}>
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/patter2.png`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2> <FormattedMessage id="enter_pattern" defaultMessage="Enter Pattern" /></h2>
                <p>
                <FormattedMessage id="enter_pattern_text" defaultMessage="This screen will appear when the user opens the app for the first time after setting the pattern or when the application is removed from the recent tabs." />
                </p>
              </div>
            </div>
          </div>

          <div class="row works-item wow fadeInUp" style={{ padding: "0px;" }}>
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/tabs.png`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2> <FormattedMessage id="more_options_screen" defaultMessage="More Options Screen" /></h2>
                <p>
                <FormattedMessage id="more_options_screen_text" defaultMessage="This screen provides the user with more options to manage settings and tabs." />
                </p>
              </div>
            </div>
          </div>

          <div class="row works-item wow fadeInUp" style={{ padding: "0px;" }}>
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/multitab.png`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2> <FormattedMessage id="tabs_management_screen" defaultMessage="Tabs Management Screen" /> </h2>
                <p>
                <FormattedMessage id="tabs_management_screen_text" defaultMessage="In this screen, the user can see the list of recently opened tabs. Users can close tabs and add a new tab using the + icon." />
                </p>
              </div>
            </div>
          </div>

          <div class="row works-item wow fadeInUp" style={{ padding: "0px;" }}>
            <div class="col-md-6 col-lg-6">
              {/* <!-- Works Item Start --> */}
              <div class="wow fadeInUp" data-wow-delay="0.25s">
                <div class="works-image text-center">
                  <figure class="image-anime">
                    <img
                      src={`${process.env.PUBLIC_URL}/Styles/images/bookmark.png`}
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div
              class="col-md-6 col-lg-6"
              style={{ alignContent: "center", justifyContent: "center" }}
            >
              <div class="works-content">
                <h2> <FormattedMessage id="bookmarks_screen" defaultMessage="Bookmarks Screen" /></h2>
                <p>
                <FormattedMessage id="bookmarks_screen_text" defaultMessage="In this screen, the user can see the list of bookmarks. Users can search for bookmarks using the search bar and remove a bookmark at any time." />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
    </IntlProvider>
  );
}

export default UserGuide;
