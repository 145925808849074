import React from 'react';
import { FormattedMessage } from 'react-intl';import { Link, useNavigate } from 'react-router-dom';


function Footer(props) {
	const navigate = useNavigate();
	const handleSectionClick = (sectionId) => {
        navigate('/'); // Navigate to home
        setTimeout(() => {
            window.location.hash = sectionId; // Scroll to the section
        }, 0);
      
    };
    return (
        <footer class="main-footer" style={{background:"black"}}>
        <div class="container">
			<div class="row" >
				<div class="col-md-12">
					{/* <!-- Mega Footer Start --> */}
					<div class="mega-footer">
						<div class="row">
							<div class="col-lg-6 col-md-12">
								{/* <!-- Footer About Start --> */}
								<div class="footer-about">
									<figure>
										{/* <!-- <img src="images/footer-logo.svg" alt=""> --> */}
									</figure>
									<p className="whitecolortext mb-4" style={{fontSize:"x-Large"}}><FormattedMessage id="Serene Browser"  defaultMessage="FeedBack3" /></p>
                                    <ul className=''>
                                        <li><a href="#" className="whitecolortext "><FormattedMessage id="Email" defaultMessage="Email" /></a></li>
                                        {/* <li className=''><a href="#" className="whitecolortext "><FormattedMessage id="Phone Number" defaultMessage="Phone Number" /></a></li> */}
                                    </ul>
								</div>
								{/* <!-- Footer About End --> */}
							</div>

							<div class="col-lg-2 col-md-12">
								{/* <!-- Footer Links Start --> */}
								<div class="footer-links">
									<h2 className="whitecolortext"><FormattedMessage id="Pages" defaultMessage="Pages" /></h2>
									<ul>
										<li><a className="whitecolortext" href="#home" onClick={() => handleSectionClick('home')}><FormattedMessage id="Home" defaultMessage="Home" /></a></li>
										<li><a className="whitecolortext" href="#about" onClick={() => handleSectionClick('about')}><FormattedMessage id="About Us" defaultMessage="About Us" /></a></li>
										<li><a className="whitecolortext" href="#features"  onClick={() => handleSectionClick('features')}><FormattedMessage id="Features" defaultMessage="Features" /></a></li>
										<li><a className="whitecolortext" href="#core" onClick={() => handleSectionClick('core')}><FormattedMessage id="Core Capabilities" defaultMessage="Core Capabilities" /></a></li>
										<li><a className="whitecolortext" href="#why" onClick={() => handleSectionClick('why')}><FormattedMessage id="Why Choose Us" defaultMessage="Why Choose Us" /></a></li>
										<li><a className="whitecolortext" href="#testimonial" onClick={() => handleSectionClick('testimonial')}><FormattedMessage id="Testimonials" defaultMessage="Testimonials" /></a></li>
									</ul>
								</div>
								{/* <!-- Footer Links End --> */}
							</div>

							{/* <!-- <div class="col-lg-2 col-md-4">
							
								<div class="footer-links">
									<h2>Socials</h2>
									<ul>
										<li><a href="#">instagram</a></li>
										<li><a href="#">facebook</a></li>
										<li><a href="#">twitter</a></li>
										<li><a href="#">linkedin</a></li>
									</ul>
								</div>
							
							</div> --> */}

							{/* <!-- <div class="col-lg-2 col-md-4">
								<div class="footer-links">
									<h2>services</h2>
									<ul>
										<li><a href="#">web development</a></li>
										<li><a href="#">digital marketing</a></li>
										<li><a href="#">game development</a></li>
										<li><a href="#">mobile app development</a></li>
										<li><a href="#">networking services</a></li>
									</ul>
								</div>
							</div> --> */}
						</div>
					</div>
					{/* <!-- Mega Footer End --> */}

					{/* <!-- Copyright Footer Start --> */}
					<div class="footer-copyright">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                {/* <!-- Footer Copyright Content Start --> */}
								<div class="footer-copyright-text">
									<p className="whitecolortext">
								<FormattedMessage id="Copyright"  defaultMessage="Copyright" />
								</p>
								</div>
								{/* <!-- Footer Copyright Content End --> */}
                            </div>
                            <div class="col-lg-6">
                                {/* <!-- Footer Policy Links Start --> */}
                                <div class="footer-policy-links">
                                    <ul>
                                        <li><a href="/policy" className="whitecolortext"><FormattedMessage id="Privacy Policy" defaultMessage="Privacy Policy" /></a></li>
                                        <li><a href="/termsofuse" className="whitecolortext"><FormattedMessage id="Terms Of Use" defaultMessage="Terms Of Use" /></a></li>
										<li><a href="/userguide" className="whitecolortext"><FormattedMessage id="User Guide" defaultMessage="User Guide" /></a></li>
                                        <li class="highlighted"><a href="#headerbar" className="whitecolortext"><FormattedMessage id="Go To Top" defaultMessage="Go To Top" /></a></li>
                                    </ul>
                                </div>
                                {/* <!-- Footer Policy Links End --> */}
                            </div>
                        </div>						
					</div>
					{/* <!-- Copyright Footer End --> */}
				</div>
			</div>
		</div>
    </footer>
    );
}

export default Footer;