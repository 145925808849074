import React from 'react';
import { FormattedMessage } from 'react-intl';

// import HeroImage from "../Styles/images/hero-img.jpg"
function MainSection(props) {
    return (
    	<div id="home" class="hero">
		<div class="container">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    {/* <!-- Hero Content Start --> */}
                    <div class="hero-content">
                        {/* <!-- Section Title Start --> */}
                        <div class="section-title">
                            {/* <!-- <h3 class="wow fadeInUp">Serene Browser</h3> --> */}
                            <h1 class="text-anime-style-3"> <FormattedMessage id="Empowering Your" defaultMessage="Empowering Your" />
                            <span><FormattedMessage id="Browsing Experience" defaultMessage="Browsing Experience" /> </span></h1>
                        </div>
                        {/* <!-- Section Title End --> */}

                        {/* <!-- Hero Body Start --> */}
                        <div class="hero-body ">
                            <p class="wow fadeInUp" data-wow-delay="0.5s"><FormattedMessage id="MainSectionHeadingDesc" defaultMessage="MainSectionHeadingDesc" />
                            </p>
                        </div>
                        {/* <!-- Hero Body End --> */}

                        {/* <!-- Hero Footer Start --> */}
                        <div class="hero-footer">
                            <a href="#" class="btn-default wow fadeInUp mr-2" data-wow-delay="0.75s"><FormattedMessage id="AppStore" defaultMessage="AppStore" /></a>
                            <a href="#" class="btn-default wow fadeInUp mr-2" data-wow-delay="0.75s"><FormattedMessage id="PlayStore" defaultMessage="PlayStore" /></a>
                            <a href="#" class="btn-default wow fadeInUp mr-2" data-wow-delay="0.75s"><FormattedMessage id="Mirror" defaultMessage="Mirror" /></a>
                        </div>
                        {/* <!-- Hero Footer End --> */}
                    </div>
                    {/* <!-- Hero Left Content End --> */}
                </div>

                <div class="col-lg-4">
                    {/* <!-- Hero Video Image Start --> */}
                    <div class="hero-video-image">
                        <div class="hero-image">
                            <figure class="image-anime reveal">
                                {/* <img src={HeroImage} alt=""/> */}
                                <img src={`${process.env.PUBLIC_URL}/Styles/images/mobileimg.png`} alt="Hero Image" />

                            </figure>
                        </div>

                        <div class="hero-play-button">
                            <a href="" class="popup-video"><i class="fa-solid fa-play"></i></a>
                        </div>
                    </div>
                    {/* <!-- Hero Video Image End --> */}
                </div>
            </div>
        </div>
	</div>
    );
}

export default MainSection;