import React from 'react';
import { FormattedMessage } from 'react-intl';

function Testimonial(props) {
    return (
        <div id="testimonial" class="clients-testimonials">
        <div class="container">
            <div class="row section-row align-items-center">
                <div class="col-lg-8 col-md-12">
                    {/* <!-- Section Title Start --> */}
                    <div class="section-title">
                        <h3 class="wow fadeInUp"><FormattedMessage id="client testimonials" defaultMessage="client testimonials" /></h3>
                        <h2 class="text-anime-style-3"><FormattedMessage id="client testimonials Heading" defaultMessage="client testimonials heading" /></h2>
                    </div>
                    {/* <!-- Section Title End --> */}
                </div>
            </div>

            <div class="row">
				<div class="col-md-12">
					{/* <!-- Testimonial Slider Start --> */}
					<div class="testimonial-slider">
						<div class="swiper">
							<div class="swiper-wrapper">
								{/* <!-- Testimonial Slide Start --> */}
								<div class="swiper-slide">
									<div class="testimonial-item">
										<div class="testimonial-rating">
                                            <img src={`${process.env.PUBLIC_URL}/Styles/images/icon-star.svg`} alt=""/>
                                        </div>										

                                        <div class="testimonial-content">
                                            <p><FormattedMessage id="FeedBack1" defaultMessage="FeedBack1" /></p>
                                        </div>											

                                        <div class="testimonial-body">
                                            <figure class="image-anime">
                                                <img src={`${process.env.PUBLIC_URL}/Styles/images/author-1.jpg`} alt=""/>
                                            </figure>
                                            <div class="testimonial-author-title">
                                                <h2><FormattedMessage id="FeedBackName1" defaultMessage="FeedBackName1" /></h2>
                                                <p><FormattedMessage id="FeedBackWork1" defaultMessage="FeedBackWork1" /></p>
                                            </div>										
                                        </div>													
									</div>
								</div>
								{/* <!-- Testimonial Slide End --> */}
                                
                                {/* <!-- Testimonial Slide Start --> */}
								<div class="swiper-slide">
									<div class="testimonial-item">
										<div class="testimonial-rating">
                                            <img src={`${process.env.PUBLIC_URL}/Styles/images/icon-star.svg`} alt=""/>
                                        </div>										

                                        <div class="testimonial-content">
                                        <p><FormattedMessage id="FeedBack2" defaultMessage="FeedBack2" /></p>
                                        </div>											

                                        <div class="testimonial-body">
                                            <figure class="image-anime">
                                                <img src={`${process.env.PUBLIC_URL}/Styles/images/author-2.jpg`} alt=""/>
                                            </figure>
                                            <div class="testimonial-author-title">
                                            <h2><FormattedMessage id="FeedBackName2" defaultMessage="FeedBackName2" /></h2>
                                            <p><FormattedMessage id="FeedBackWork2" defaultMessage="FeedBackWork2" /></p>
                                            </div>										
                                        </div>													
									</div>
								</div>
								{/* <!-- Testimonial Slide End --> */}

                                {/* <!-- Testimonial Slide Start --> */}
								<div class="swiper-slide">
									<div class="testimonial-item">
										<div class="testimonial-rating">
                                            <img src={`${process.env.PUBLIC_URL}/Styles/images/icon-star.svg`} alt=""/>
                                        </div>										

                                        <div class="testimonial-content">
                                        <p><FormattedMessage id="FeedBack3" defaultMessage="FeedBack3" /></p>
                                        </div>											

                                        <div class="testimonial-body">
                                            <figure class="image-anime">
                                                <img src={`${process.env.PUBLIC_URL}/Styles/images/author-3.jpg`} alt=""/>
                                            </figure>
                                            <div class="testimonial-author-title">
                                            <h2><FormattedMessage id="FeedBackName3" defaultMessage="FeedBackName3" /></h2>
                                            <p><FormattedMessage id="FeedBackWork3" defaultMessage="FeedBackWork3" /></p>
                                            </div>										
                                        </div>													
									</div>
								</div>
								{/* <!-- Testimonial Slide End --> */}

                                {/* <!-- Testimonial Slide Start --> */}
								<div class="swiper-slide">
									<div class="testimonial-item">
										<div class="testimonial-rating">
                                            <img src={`${process.env.PUBLIC_URL}/Styles/images/icon-star.svg`} alt=""/>
                                        </div>										

                                        <div class="testimonial-content">
                                        <p><FormattedMessage id="FeedBack4" defaultMessage="FeedBack4" /></p>
                                        </div>											

                                        <div class="testimonial-body">
                                            <figure class="image-anime">
                                                <img src={`${process.env.PUBLIC_URL}/Styles/images/author-2.png`} alt=""/>
                                            </figure>
                                            <div class="testimonial-author-title">
                                            <h2><FormattedMessage id="FeedBackName4" defaultMessage="FeedBackName4" /></h2>
                                            <p><FormattedMessage id="FeedBackWork4" defaultMessage="FeedBackWork4" /></p>
                                            </div>										
                                        </div>													
									</div>
								</div>
								{/* <!-- Testimonial Slide End -->              */}
							</div>
							<div class="swiper-pagination"></div>
						</div>
					</div>
					{/* <!-- Testimonial Slider End --> */}
				</div>
			</div>
        </div>
    </div>
    );
}

export default Testimonial;