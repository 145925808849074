import React from 'react';
import { FormattedMessage } from 'react-intl';

function WorkSection(props) {
    return (
        <div id="core" class="our-work">
        <div class="container">
            <div class="row section-row align-items-center">
                <div class="col-lg-8 col-md-9">
                    {/* <!-- Section Title Start --> */}
                    <div class="section-title">
                        <h3 class="wow fadeInUp"><FormattedMessage id="Core Capabilities" defaultMessage="Core Capabilities" /></h3>
                        <h2 class="text-anime-style-3"><FormattedMessage id="Core CapabilitiesDesc" defaultMessage="Core CapabilitiesDesc" /></h2>
                    </div>
                    {/* <!-- Section Title End --> */}
                </div>

                <div class="col-lg-4 col-md-3">
                    {/* <!-- Section Btn Start --> */}
                    <div class="section-btn wow fadeInUp" data-wow-delay="0.25s">
                        {/* <!-- <a href="#" class="btn-default">all portfolio</a> --> */}
                    </div>
                    {/* <!-- Section Btn End --> */}
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    {/* <!-- Works Item Start --> */}
                    <div class="works-item wow fadeInUp" data-wow-delay="0.25s">
                        <div class="works-image">
                            <figure class="image-anime">
                                <img src="https://storage.googleapis.com/a1aa/image/4FSc1LyFy6YIMlJTYwQkGRdvEVbmcNZVviGP936Y9J3TVS4E.jpg" alt=""/>
                            </figure>
                        </div>
                        <div class="works-content">
                            <h2><FormattedMessage id="Performance Optimization" defaultMessage="Performance Optimization" /></h2>
                            <p><FormattedMessage id="Performance OptimizationDesc" defaultMessage="Performance OptimizationDesc" /></p>
                        </div>
                    </div>
                    {/* <!-- Works Item End --> */}
                </div>

                <div class="col-md-6">
                    {/* <!-- Works Item Start --> */}
                    <div class="works-item wow fadeInUp" data-wow-delay="0.5s">
                        <div class="works-image">
                            <figure class="image-anime">
                                <img src="https://storage.googleapis.com/a1aa/image/hoqZx5fg1IQPSyiC8IIRUKa4hKnr5q19PuacCk2Nv8H9qkwJA.jpg" alt=""/>
                            </figure>
                        </div>
                        <div class="works-content">
                            <h2><FormattedMessage id="Security and Privacy" defaultMessage="Security and Privacy" /></h2>
                            <p><FormattedMessage id="Security and PrivacyDesc" defaultMessage="Security and PrivacyDesc" /></p>
                        </div>
                    </div>
                    {/* <!-- Works Item End --> */}
                </div>

                <div class="col-md-6">
                    {/* <!-- Works Item Start --> */}
                    <div class="works-item wow fadeInUp" data-wow-delay="0.75s">
                        <div class="works-image">
                            <figure class="image-anime">
                                <img src="https://storage.googleapis.com/a1aa/image/hEmGypaZ3U6GMJeMNxVwuRa3qyzIswtkeMbWXWDgAZbqtJhTA.jpg" alt=""/>
                            </figure>
                        </div>
                        <div class="works-content">
                            <h2><FormattedMessage id="User-Friendly Interface" defaultMessage="Security and Privacy" /></h2>
                            <p><FormattedMessage id="User-Friendly InterfaceDesc" defaultMessage="Security and Privacy" /></p>
                        </div>
                    </div>
                    {/* <!-- Works Item End --> */}
                </div>

                <div class="col-md-6">
                    {/* <!-- Works Item Start --> */}
                    <div class="works-item wow fadeInUp" data-wow-delay="1s">
                        <div class="works-image">
                            <figure class="image-anime">
                                <img src="https://storage.googleapis.com/a1aa/image/0gjkDSBeUKzLekZnm5rzL4fxHPQ4SWIRXt1DLT9DS2mp2SCnA.jpg" alt=""/>
                            </figure>
                        </div>
                        <div class="works-content">
                            <h2><FormattedMessage id="Efficient Browsing Controls" defaultMessage="Efficient Browsing Controls" /></h2>
                            <p><FormattedMessage id="Efficient Browsing ControlsDesc" defaultMessage="Efficient Browsing ControlsDesc" /></p>
                        </div>
                    </div>
                    {/* <!-- Works Item End --> */}
                </div>
            </div>
        </div>
    </div>
    );
}

export default WorkSection;