import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

function AboutSection(props) {
    useEffect(() => {
        // Create a script element
        const script = document.createElement('script');
        script.src = `${process.env.PUBLIC_URL}/styles/js/function.js`;
        script.async = true; // Load the script asynchronously
        document.body.appendChild(script);
    
        // Clean up the script when the component unmounts
        return () => {
          document.body.removeChild(script);
        };
      }, []);
    return (
        <div id="about" class="about-us">
        <div class="container">
            <div class="row section-row align-items-center">
                <div class="col-lg-8">
                    {/* <!-- Section Title Start --> */}
                    <div class="section-title">
                        <h3 class="wow fadeInUp"><FormattedMessage id="About Serene" defaultMessage="About Serene" /></h3>
                        <h2 class="text-anime-style-3"><FormattedMessage id="About Serene Desc" defaultMessage="About Serene Desc" /></h2>
                    </div>
                    {/* <!-- Section Title End --> */}
                </div>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-6">
                    {/* <!-- About Us Image Start --> */}
                    <div class="about-image">
                        <div class="about-img">``
                            <figure class="image-anime reveal">
                                <img src={`${process.env.PUBLIC_URL}/Styles/images/about-us-img.jpg`} alt=""/>
                            </figure>
                        </div>
                        <div class="about-consultation">
                            <figure>
                                <img src={`${process.env.PUBLIC_URL}/Styles/images/serenecircle.png`} alt="" style={{borderRadius:"90px"}}/>
                            </figure>
                        </div> 
                    </div>
                    {/* <!-- About Us Image End --> */}
                </div>

                <div class="col-lg-6">
                    {/* <!-- About Us Content Start --> */}
                    <div class="about-content">
                        <p class="wow fadeInUp" data-wow-delay="0.25s"><FormattedMessage id="About Text Description" defaultMessage="About Text Description" /></p>
                        {/* <!-- <p class="wow fadeInUp" data-wow-delay="0.5s">totam rem aperiam, eaque ipsa quae ab illo inventore et quasi architecto beatae vitae dicta sunt explicabo. sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p> 
                        
                        <p class="wow fadeInUp" data-wow-delay="0.75s">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.</p> */}

                        <ul class="wow fadeInUp" data-wow-delay="1s">
                            <li><FormattedMessage id="Lightning-fast performance" defaultMessage="Lightning-fast performance" /></li>
                            <li><FormattedMessage id="Cross-platform compatibility" defaultMessage="Cross-platform compatibility" /></li>
                            <li><FormattedMessage id="Seamless user experience" defaultMessage="Seamless user experience" /></li>
                            <li><FormattedMessage id="Advanced security features" defaultMessage="Advanced security features" /></li>
                            <li><FormattedMessage id="Customizable browsing" defaultMessage="Customizable browsing" /></li>
                            <li><FormattedMessage id="Efficient data usage" defaultMessage="Efficient data usage" /></li>
                        </ul>

                        {/* <!-- <a href="#" class="btn-default wow fadeInUp" data-wow-delay="1.25s">free consultation</a> --> */}
                    </div>
                    {/* <!-- About Us Content End --> */}
                </div>
            </div>
        </div>
    </div>
    );
}

export default AboutSection;