import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "../src/Components/Header.jsx";
import MainSection from "../src/Components/MainSection.jsx";
import About from "../src/Components/AboutSection.jsx";
import Features from "../src/Components/FeaturesSection.jsx";
import WorkSection from "../src/Components/WorkSection.jsx";
import WhyChooseUs from "../src/Components/WhyChooseUs.jsx";
import Testimonials from "../src/Components/Testimonial.jsx";
import "./App.css";
import Footer from "../src/Components/Footer.jsx";
import enMessages from "./locales/en.json";
import zhMessages from "./locales/zh.json";
import ptMessages from "./locales/pt.json";
import viMessages from "./locales/vi.json";
import { FormattedMessage, IntlProvider, useIntl } from "react-intl";
import Loader2 from "./Components/Loader2.jsx";

const supportedLanguages = [
  "en",
  "zh",
  "pt",
  "vi",
]; // Add your supported languages here
function App() {
  const intl = useIntl();
  const browserLanguage = (navigator.language || navigator.userLanguage).split(
    "-"
  )[0];

  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [headerHeight, setHeaderHeight] = useState(0);
  const messages = {
    en: enMessages,
    zh: zhMessages,
    pt: ptMessages,
    vi: viMessages,
  };
  // const handleLanguageChange = (e) => {
  //   const language = e.currentTarget.getAttribute("value");
  //   setSelectedLanguage(language);
  //   localStorage.setItem("selectedLanguage", language); // Store selected language
  //   window.location.reload();
  // };

  const handleLanguageChange = (e) => {
    const language = e.currentTarget.getAttribute("value");
    localStorage.setItem("selectedLanguage", language); // Store selected language

    // Delay the language change logic for 3 seconds after reload
    setTimeout(() => {
      setSelectedLanguage(language); // Update the language state after 3 seconds
    }, 3000);

    // Reload the page immediately
    window.location.reload();
  };

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");

    if (storedLanguage && supportedLanguages.includes(storedLanguage)) {
      setSelectedLanguage(storedLanguage);
    } else if (supportedLanguages.includes(browserLanguage)) {
      setSelectedLanguage(browserLanguage);
    } else {
      setSelectedLanguage("en"); // Fallback to default language
    }
  }, [browserLanguage]);

  // Scroll to top on click
  return (
    <IntlProvider
      locale={selectedLanguage}
      messages={messages[selectedLanguage]}
    >
      <div className="App">
        <Loader2 />
        <Header handleLanguageChange={handleLanguageChange} />
        <MainSection />
        <About />
        <Features />
        <WorkSection />
        <WhyChooseUs />
        <Testimonials />
        <Footer />
      </div>
    </IntlProvider>
  );
}

export default App;
